.container {
    margin-top: 30vh;
    margin-left: 8%;
}

.row col-6 card {
    width: 100%;
    
}

.etiquet {
    margin: 0;
    padding: 0;
    width: 100%;
    
}

.card-header {
    background: rgb(0, 0, 0);
    background-size: 10%;
    width: 100%;
    margin: 0;
    color: rgb(255, 255, 0);
    font-size: 20px;
    border: 2px solid yellow;
    
}

.log {
    background: black;
    width: 75px;
    height: 35px;
    border-radius: 8px;
    border-radius: 20px;
    padding: 6px 12px;
    color: blue;
    text-align: center;
}

.card-body {
    background: rgb(128, 125, 125);
}

.card-footer {
    background: rgb(195, 194, 194);
    background-size: 100%;
    width: 100%;
    padding-left: 55px;
    margin: 0;
}

.col-12 input {
    border: 2px solid yellow;
}

button {
    border: 2px solid yellow;
}


