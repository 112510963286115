.result{
    background-color: rgb(0, 0, 0);
    width: 100%;
    height: 100%;

}

.winner {
    margin-left: 41vw;
    margin-top: 8vw;
    font-size: 5vw;
    color: #fff;
}

.lineprimer {
    margin-left: 60vw;
    margin-top: 30vw;
    font: bold;
    color: #fff;
    font-size: 4vw;
    text-align: center;
}

.linepale {
    margin-left: 60vw;
    margin-top: 14vw;
    font: bold;
    color: #fff;
    font-size: 7vw;
    text-align: center;
}

.ontcont {
    position: absolute;
    margin-left: 1%;
    top: 7.5vw;
}

.ont_12 {
    display: grid;
    grid-template-columns: 15vw 5vw 5vw;
    grid-template-rows: repeat(8, minmax(1vw, 5vw));
    height: 36.4vw; 
    row-gap: 0.48vw;
    column-gap: 0.3vw;
}

.otonz_1 {
    display: table;
    border-radius: 8px;
}

.umz_1 {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    font-size: 2vw; 
    font-weight:bold;
    left: 12vw;
    border-radius: 0.4em;
}

.otonz_1 .umz_11 {
    
    display: table-cell;
    margin: 0;
    vertical-align: middle;
    font: bold;
    text-align: center;
    font-size: 2.6vw; 
    border: 2px solid blue;
    border-radius: 0.2em;
}

.otonz_1:nth-child(1) {
    font-weight:bold;
    background: linear-gradient(#fff, rgba(230,230,230,1), #fff, #fdfdfd, #fff, rgba(230,230,230,1));
}

.otonz_1:nth-child(2) {
    background-color: white;
    font-size: 1.3vw;
    font-weight:bold;
}

.otonz_1:nth-child(3) {
    background-color: red;
    font-size: 2.3em;
    font-weight:bold;
    color:#fdfdfd;
}

.otonz_1:nth-child(4) {
    background: linear-gradient(#fff, rgba(230,230,230,1), #fff, #fdfdfd, #fff, rgba(230,230,230,1));
}

.btonz_1:nth-child(5) {
    font-weight: bolder;
    background: repeating-linear-gradient(#fff, #111, #fff, #111, #fff, #111, #fff, #111);
}

.otonz_1:nth-child(6) {
    background-color: rgb(29, 10, 234);
    font-size: 2.3em;
    font-weight:bold;
    color:#fdfdfd;
    border-color: rgb(11, 11, 11);
}

.otonz_1:nth-child(7) {
    background: linear-gradient(#fff, rgba(230,230,230,1), #fff, #fdfdfd, #fff, rgba(230,230,230,1));
}

.otonz_1:nth-child(8) {
    font-weight: bolder;
    background: repeating-linear-gradient(#fff, #111, #fff, #111, #fff, #111, #fff, #111)
}

.otonz_1:nth-child(9) {
    background-color: rgb(255, 225, 0);
    font-size: 2.3em;
    font-weight:bold;
    color:#111;
}

.otonz_1:nth-child(10) {
    background: linear-gradient(#fff, rgba(230,230,230,1), #fff, #fdfdfd, #fff, rgba(230,230,230,1));
}

.otonz_1:nth-child(11) {
    background-color: red;
    font-size: 2.3em;
    font-weight:bold;
    color:#fdfdfd;
}

a {
    text-decoration: none;
}

.otonz_1:nth-child(12) {
    background-color: white;
    font-size: 2.3em;
    font-weight:bold;
}

.otonz_1:nth-child(13) {
    background: linear-gradient(#fff, rgba(230,230,230,1), #fff, #fdfdfd, #fff, rgba(230,230,230,1));
}

.otonz_1:nth-child(14) {
    background-color: red;
    font-size: 2.3em;
    font-weight:bold;
    color:#fdfdfd;
}

.otonz_1:nth-child(15) {
    font-weight: bolder;
    background: repeating-linear-gradient(#fff, #111, #fff, #111, #fff, #111, #fff, #111)
}

.otonz_1:nth-child(16) {
    font-weight: bolder;
    background: linear-gradient(#fff, rgba(230,230,230,1), #fff, #fdfdfd, #fff, rgba(230,230,230,1));
}

.otonz_1:nth-child(17) {
    background-color: red;
    font-size: 2.3em;
    font-weight:bold;
    color:#fdfdfd;
}

.otonz_1:nth-child(18) {
    background-color: #111;
    font-size: 2.3em;
    font-weight:bold;
    color:#fdfdfd;
}

.otonz_1:nth-child(19) {
    font-weight: bolder;
    background: linear-gradient(#fff, rgba(230,230,230,1), #fff, #fdfdfd, #fff, rgba(230,230,230,1));
}

.otonz_1:nth-child(20) {
    background-color: rgb(29, 10, 234);
    font-weight:bold;
    color:#fdfdfd;
    border-color: rgb(11, 11, 11);
}

.otonz_1:nth-child(21) {
    background-color: rgb(255, 225, 0);
    font-weight:bold;
    color:#111;
}
.otonz_1:nth-child(22) {
    font-weight: bolder;
    background: linear-gradient(#fff, rgba(230,230,230,1), #fff, #fdfdfd, #fff, rgba(230,230,230,1));
}

.otonz_1:nth-child(23) {
    background-color: rgb(255, 225, 0);
    font-weight:bold;
    color:#111;
}

.otonz_1:nth-child(24) {
    font-weight: bolder;
    background: repeating-linear-gradient(#fff, #111, #fff, #111, #fff, #111, #fff, #111) 
}

.arrera_num p {
    color:white;
    font-family: 'Times New Roman', Times, serif;
}

.umero_carrera {
    font-family: 'Times New Roman', Times, serif;
    position: absolute;
    top: 0vw;
    left: 16vw;
    font-size: 5.2vw;  
}

.iulo_carrera p {
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif !important;
    position: absolute;
    top: 2.3vw;
    left: 11.9vw;
    transform: rotate(270deg);
    font-size: 1.7vw;
    letter-spacing: 0.02vw;
}

.istorial p {
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif !important;
    position: absolute;
    top: 0vw;
    left: 0.9vw;
    font-size: 2.9vw;
    letter-spacing: 0.02vw;
    color: rgb(255, 255, 0);
}

.lecha {
    position: absolute;
    top: 3.8vw;
    left: 5.2vw;
    border-top: 3vw solid red;
    border-bottom: 3vw solid transparent;
    border-right: 3vw solid transparent;
    border-left: 3vw solid transparent;
}

.onus {
    position: absolute;
    margin-top: 32.2vw;
}

.on {
    font-family: 'Courier New', Courier, monospace;
    font-size: 1.5vw;
    margin-left: 0.5vw;
    background-color: rgb(255, 0, 0);
    width: 3.75vw;
    color: rgb(81, 255, 0);
}

.qu {
    position: absolute;
    font-family: 'Courier New', Courier, monospace;
    text-align: center;
    font-size: 2vw;
    top: 1.8vw;
    margin-left: 0.5vw;
    background-color: rgb(255, 0, 0);
    width: 3.75vw;
    height: 2.5vw;
    color: rgb(255, 255, 255);
}

.ackpots p, p1 {
    color:white;
    font-family: 'Times New Roman', Times, serif;
}

.onteo_general {
    font-family: 'Times New Roman', Times, serif;
    position: absolute;
    top: 40.6vw;
    left: 84%;
    font-size: 4vw;
    color: #fff;  
}

.onteo_local {
    font-family: 'Times New Roman', Times, serif;
    position: absolute;
    top: 40.6vw;
    left: 46.8%;
    font-size: 4vw;  
}

.ackpot_general p {
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif !important;
    position: absolute;
    top: 41.6vw;
    left: 64%;
    font-size: 3vw;
    letter-spacing: 0.02px;
    color: rgb(255, 255, 0);
}

.ackpot_local p {
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif !important;
    position: absolute;
    top: 41.6vw;
    left: 31%;
    font-size: 3vw;
    letter-spacing: 0.02px;
    color: rgb(255, 255, 0);
}

.ackpot_local p1 {
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif !important;
    position: absolute;
    top: 42.2vw;
    left: 41%;
    font-size: 2.5vw;
    letter-spacing: 0.02px;
    color: rgb(255, 255, 0);
}

/* .rogreso {
    position: absolute;
    top: 4.6vw;
    width: 61.7%;
    height: 1.2vw;
    margin-left: 37.2%;    
}

.rogress {
    width: 100%;
    height: 250%;
} */

.orrien {
    position: absolute;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-style: italic;
    left: 45.9vw;
    top: -0.3vw;
    font-size: 4vw;
    color: rgb(0, 0, 0);
    text-shadow: 0 0 1px red, 0 0 3px red, 0 0 5px red, 0 0 7px red, 0 0 9px red, 0 0 12px red, 0 0 16px red;
}

.og {
    position: absolute;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-style: italic;
    color: rgb(255, 255, 255);
    top: -0.7vw;
    left: 58.5vw;
    font-size: 4.5vw;
    text-shadow: 0 0 1px red, 0 0 3px red, 0 0 5px red, 0 0 7px red, 0 0 9px red, 0 0 12px red, 0 0 16px red, 0 0 20px red;  
}

/* .imepadre{
    position: absolute;
    margin-left: 30.2vw;
    top: 5.3vw;
    border-radius: 50px;
    background-color: rgb(255, 255, 255);
} */

/* .ime{
    display: table-cell;
    font-size: 1.2vw;
    color: rgb(0, 0, 0);
    font-weight: bolder;
    font-family: Verdana, Geneva, Tahoma, sans-serif;    
    width: 7vw;
    height: 1.5vw;
    text-align: center;
    vertical-align: middle;
} */

.winprimero {
    position: absolute;
    width: 15vw;
    height: 8vw;
    margin-left: 35vw;
    margin-top: -26vw;
    border: solid #fdfdfd;
    border-radius: 10%;
    color : white;
    text-align: center;
}
.winprimero1 {
    position: absolute;
    width: 15vw;
    height: 8vw;
    margin-left: 51vw;
    margin-top: -26vw;
    border: solid #fdfdfd;
    border-radius: 10%;
    color : white;
    text-align: center;
}
.winsegundo {
    position: absolute;
    width: 15vw;
    height: 8vw;
    margin-left: 51vw;
    margin-top: -11vw;
    border: solid #fdfdfd;
    border-radius: 10%;
    color : white;
    text-align: center;
}

.win1pale {
    position: absolute;
    width: 15vw;
    height: 8vw;
    margin-left: 35vw;
    margin-top: -11vw;
    border: solid #fdfdfd;
    border-radius: 10%;
    color : white;
    text-align: center;
}