.imgLogoPrint{
    width: 200px;
    object-fit: cover;
    }
    .divLogoPrint{
    
    }
    .noDisplay {
        display: none;
    }
    @media print{
    .noPrint{
        display: none;
    }
    .yPrint{
        display: block;
    }
    }