
  /* Media query para la impresión */
  @media print {
    *{
        padding: 0;
        margin: 0;
    }
    .print {
      background-color: white;
      color: black;
      border: none;
      font-size: 0.7em;
      font-family:Verdana, Geneva, Tahoma, sans-serif;
      width: 100%; /* Asegura que el contenido se ajuste al ancho del papel */
      margin: 0; /* Elimina márgenes predeterminados del navegador */
      padding: 0; /* Elimina relleno predeterminado del navegador */
    }
    .classbetted{
      background-color: gray;
    }
    .modal {
        max-width: 100% !important;
      }
    /* Agrega estilos adicionales para la impresión si es necesario */
    /* Por ejemplo, puedes ocultar ciertos elementos que no quieres imprimir */
    .no-print {
      display: none;
    }
    .bl{
        width: 10%;
    }
    .pl{
        width: 10%;
    }
    .traba{
        width: 40%
    }
    .re{
        width: 10%;
    }
    .time{
        width: 10%;
    }
    .hr{
        width: 20%;
    }
  }

  @page{
    margin: 0;
  }
  