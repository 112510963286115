* {
    padding: 0;
    margin: 0;
}

body {
    max-height: 100%;
    min-height: 0vh;
    padding: 0; 
    background-size: 200%;
    margin: 0%;
}

.flotante_bono {
    display: grid;
    grid-template-columns: 0vw 1fr 0vw;
    grid-template-rows: 10vh 1fr 1fr 0vh;
    padding: 0;
    margin: 0%;
    height: 100vh;
}

p {
    height: 100%;
    padding: 0;
    margin: 0;
}

div {
    margin: 0;
    padding: 0;
}

.fila {
    display: table;
    margin: 0;
    padding: 0;
}

.fila1 {
    display: table;
    margin: 0;
    padding: 0;
}

.fila2 {
    display: table;
    margin: 0;
    padding: 0;
}

.corri {
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-style: italic;
    font-size: 4vw;
    margin-left: 22vw;
    color: rgb(0, 0, 0);
    text-shadow: 0 0 1px red, 0 0 3px red, 0 0 5px red, 0 0 7px red, 0 0 9px red, 0 0 12px red, 0 0 16px red;
}

.can {
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-style: italic;
    color: rgb(255, 255, 255);
    font-size: 4vw;
    margin-right: 0;
    text-shadow: 0 0 1px red, 0 0 3px red, 0 0 5px red, 0 0 7px red, 0 0 9px red, 0 0 12px red, 0 0 16px red, 0 0 20px red;
}

.bonflo {
    display: table-cell;
    position: relative;
    top:12vh;
    font-family: 'Courier New', Courier, monospace;
    font-size: 8vw;
    color: rgb(255, 89, 0);
    text-align: center;
    animation-name: mover;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    text-shadow: 0px 0px 40px rgb(255, 255, 255), 0px 0px 40px rgb(255, 255, 255), 0px 0px 50px rgb(255, 255, 255), 0px 0px 60px rgba(255, 255, 255);
}

@keyframes mover {
    0% {
        font-size: 8vw;
        color: rgb(255, 255, 255);
        text-shadow: 0px 0px 40px rgb(255, 0, 0), 0px 0px 40px rgb(255, 0, 0), 0px 0px 50px rgb(255, 0, 0), 0px 0px 60px rgba(255, 0, 0) ;
    }

    25%{
        color: rgb(255, 255, 255);
        font-size: 8vw;
        text-shadow: 0px 0px 40px rgb(255, 0, 0), 0px 0px 40px rgb(255, 0, 0), 0px 0px 50px rgb(255, 0, 0), 0px 0px 60px rgba(255, 0, 0);
    }

    50%{
        color: rgb(255, 255, 255);
        font-size: 8vw;
        text-shadow: 0px 0px 40px rgb(255, 0, 0), 0px 0px 40px rgb(255, 0, 0), 0px 0px 50px rgb(255, 0, 0), 0px 0px 60px rgba(255, 0, 0);
    }
    
    75%{
        color: rgb(255, 1, 1);
        font-size: 8vw;
        text-shadow: 0px 0px 40px rgb(255, 255, 255), 0px 0px 40px rgb(255, 255, 255), 0px 0px 50px rgb(255, 255, 255), 0px 0px 60px rgba(255, 255, 255);
    }

    100%{
        color: rgb(255, 255, 255);
        font-size: 8vw;
        text-shadow: 0px 0px 40px rgb(255, 0, 0), 0px 0px 40px rgb(255, 0, 0), 0px 0px 50px rgb(255, 0, 0), 0px 0px 60px rgba(255, 0, 0);
    }
}

.equflo {
    display: table-cell;
    position: relative;
    top: -8vh;
    font-family: 'Courier New', Courier, monospace;
    padding-top: 0;
    text-align: center;
    font-size: 25vw;
    color: rgb(255, 255, 255);
    background-size: 100%;
    margin: 0;
    animation-name: latir;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    text-shadow: 0px 0px 40px rgb(255, 0, 0), 0px 0px 40px rgb(255, 0, 0), 0px 0px 50px rgb(255, 0, 0), 0px 0px 60px rgba(255, 0, 0);
}

@keyframes latir {
    0% {
        font-size: 25vw;
        color: rgb(255, 255, 255);
        text-shadow: 0px 0px 40px rgb(255, 0, 0), 0px 0px 40px rgb(255, 0, 0), 0px 0px 50px rgb(255, 0, 0), 0px 0px 60px rgba(255, 0, 0);
    }

    25%{
        font-size: 22vw;
        color: rgb(255, 255, 255);
    }

    50%{
        color: rgb(255, 0, 0);
        font-size: 20vw;
        text-shadow: 0px 0px 40px rgb(255, 255, 255), 0px 0px 40px rgb(255, 255, 255), 0px 0px 50px rgb(255, 255, 255), 0px 0px 60px rgba(255, 255, 255);
    }
    
    75%{
        color: rgb(255, 0, 0);
        font-size: 22vw;
        text-shadow: 0px 0px 40px rgb(255, 255, 255), 0px 0px 40px rgb(255, 255, 255), 0px 0px 50px rgb(255, 255, 255), 0px 0px 60px rgba(255, 255, 255);
    }

    100%{
        color: rgb(255, 255, 255);
        font-size: 25vw;
        text-shadow: 0px 0px 40px rgb(255, 0, 0), 0px 0px 40px rgb(255, 0, 0), 0px 0px 50px rgb(255, 0, 0), 0px 0px 60px rgba(255, 0, 0);
    }
}

