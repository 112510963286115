body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/*
video::-webkit-media-controls-fullscreen-button {display: none;}
video::-webkit-media-controls-play-button { display: none;}
video::-webkit-media-controls-timeline { display: none;}
video::-webkit-media-controls-current-time-display{ display: none;}
video::-webkit-media-controls-time-remaining-display { display: none;}
video::-webkit-media-controls-mute-button { display: none;}
video::-webkit-media-controls-toggle-closed-captions-button { display: none;}
video::-webkit-media-controls-volume-slider { display: none;}

::-webkit-scrollbar {
  display: none;
}*/