.containerVenta{
    margin-top: 30%;
    justify-content: center;
    /* display: flex; */
    background-color: white;
    width: 80%;
}
.item{
    padding-left: 10%;
}

.gridVenta {
    display: grid;
    grid-template-columns: 25vw 2vw 25vw;
    grid-template-rows: 2vw 2vw 2vw 2vw;
    gap: 1vw;
}

.dateInit {
    grid-column: 1/2;
    grid-row: 1/2;
}

.dateEnd {
    grid-row: 2/3;
    grid-column: 1/2;
}

.btnventas {
    grid-column: 1/2;
    grid-row: 3/4;
}

.print {
    grid-column: 1/2;
    grid-row: 4/5;
}

.apostado {
    grid-column: 3/4;
    grid-row: 1/2;
}
.ganado {
    grid-column: 3/4;
    grid-row: 2/3
}
.pagado {
    grid-column: 3/4;
    grid-row: 3/4;
}
.balance {
    grid-column: 3/4;
    grid-row: 4/5
}

.center1 {
    grid-column: 2/3;
    grid-row: 1/2;
}

.center2 {
    grid-column: 2/3;
    grid-row: 2/3;
}

.center3 {
    grid-column: 2/3;
    grid-row: 3/4;
}

.center4 {
    grid-column: 2/3;
    grid-row: 4/5;
}
