.App {
  text-align: center;

}

.papaError{
  position: relative;
}
.error{
  position: absolute;
  color: yellow;
  text-align: center;
  font-size: 6em;
  margin-top: 40vh;
  /* margin-bottom: auto; */
 

}

