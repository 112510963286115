li{
    display: inline;
    padding: 0%;
    text-decoration: none;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1rem;
}
a{
    text-decoration: none;
}
.body-container{
    height: 100%;
}
.divNav{
    width: 0;
    background-color: black;
}
.new-point{
    height: 100%;
}

@media screen and (max-width: 360px) {
    * {
  font-size: 8px;

    }
}
@media screen and (min-width: 360px) and (max-width:600px) {
    *{
      font-size: 10px;  
    }
}
@media screen and (min-width: 660px) and (max-width:1500px) {
    *{
      font-size: 12px;  
    }
}