.gridJacpot {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 20vh 25vh 30vh 15vh;
}

.contJacpot {
    border: 3px solid red;
    width: 100vw;
    height: 100vh;
    background-image: url(./jp.gif);
    background-repeat: no-repeat;
    background-size: cover;
    /* background-size: 100vh; */
    aspect-ratio: 1/8;

}

.textTipo{
    text-align: center;
}

.textJack {
    text-align: center;
    font-size: 10vw;
    color: rgb(255, 255, 0);
    font-weight: bold;
    text-shadow: 0 0 3px rgb(255, 0, 0), 0 0 4px rgb(255, 0, 0), 0 0 5px rgb(255, 0, 0), 0 0 6px rgb(255, 0, 0), 0 0 7px rgb(255, 0, 0), 0 0 8px rgb(255, 255, 0), 0 0 10px rgb(255, 255, 0), 0 0 15px rgb(255, 0, 0), 0 0 17px rgb(255, 0, 0), 0 0 18px rgb(255, 0, 0), 0 0 19px rgb(255, 0, 0), 0 0 21px rgb(255, 0, 0), 0 0 23px rgb(255, 0, 0), 0 0 24px rgb(255, 0, 0), 0 0 25px rgb(255, 0, 0), 0 0 26px rgb(255, 0, 0), 0 0 27px rgb(255, 0, 0), 0 0 28px rgb(255, 255, 0), 0 0 34px rgb(255, 255, 0), 0 0 38px rgb(255, 255, 0), 0 0 43px rgb(255, 255, 0), 0 0 45px rgb(255, 255, 0), 0 0 47px rgb(255, 255, 0);

}

.textLcl {
    /* text-align: center; */
    font-size: 8vw;
    color: rgb(255, 255, 0);
    font-weight: bold;
    text-shadow: 0 0 13px rgb(255, 0, 0);
}

.textGeral {
    /* text-align: center; */
    
    font-size: 25vh;
    color: rgb(255, 255, 0);
    font-weight: bold;
    text-shadow: 0 0 13px rgb(255, 0, 0);
    animation-name: zoom;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
    height: 100%;
}

@keyframes zoom {
    0% {
        /* font-size: 15vw; */
        color: rgb(255, 255, 255);
        text-shadow: 0px 0px 40px rgb(255, 0, 0), 0px 0px 40px rgb(255, 0, 0), 0px 0px 50px rgb(255, 0, 0), 0px 0px 60px rgba(255, 0, 0);
    }

    25%{
        /* font-size: 12vw; */
        color: rgb(255, 255, 255);
    }

    50%{
        color: rgb(255, 0, 0);
        /* font-size: 10vw; */
        text-shadow: 0px 0px 40px rgb(255, 255, 255), 0px 0px 40px rgb(255, 255, 255), 0px 0px 50px rgb(255, 255, 255), 0px 0px 60px rgba(255, 255, 255);
    }
    
    75%{
        color: rgb(255, 0, 0);
        /* font-size: 12vw; */
        text-shadow: 0px 0px 40px rgb(255, 255, 255), 0px 0px 40px rgb(255, 255, 255), 0px 0px 50px rgb(255, 255, 255), 0px 0px 60px rgba(255, 255, 255);
    }

    100%{
        
        color: rgb(255, 255, 255);
        /* font-size: 8vw; */
        text-shadow: 0px 0px 40px rgb(255, 0, 0), 0px 0px 40px rgb(255, 0, 0), 0px 0px 50px rgb(255, 0, 0), 0px 0px 60px rgba(255, 0, 0);
    }
}

.textMonto{
    text-align: center;
    font-size: 10vw;
    color: rgb(255, 255, 0);
    font-weight: bold;
    text-shadow: 0 0 18px rgb(0, 255, 8);
}

.textTicket {
    text-align: center;
    font-size: 5vw;
    color: rgb(255, 255, 0);
    font-weight: bold;
    text-shadow: 0 0 13px rgb(255, 255, 255);
}