body {
    margin: 0;
    padding: 0%;
    height: 99vh;
    width: 99vw;
    font-family: Bahnschrift;
    background-color: #000000;


}
.general{}
 .td{
    width: 11%;
    height: 100%;
    margin: 100px;
    padding: 100px;
    align-items: center;
    vertical-align: middle;
    font-size: 2vw;
    border: 15px;
}
 .th{
    font-size: 2vw;
}
.th-modal{
font-size: 2.2em;
}

.divHeight75{
    height: 75vh;
    font-size: 2rem;

}
.divHeight25{
    height: 25vh;

}
.bg-table{
background-color: rgb(187, 185, 185)
}
.grilla1 {
    grid-template-columns: 21% 3% 74%;
    grid-template-rows: 2vh max(7vh) max(4vh) max(70vh) max(6.8vh) max(7.8vh) 0.4fr;
    column-gap: .5vw;
    aspect-ratio: none;
    height: 98%;
    width: 100%;
}

.gri1 {
    grid-column: 1/4;
}

.gri2 {
    grid-row: 2/4;
    grid-column: 1/3;
    margin: 0;
    padding: 0;
    justify-items: center;
}

.carrera_num {
    display: grid;
    grid-template-rows: 50% 50%;
    grid-template-columns: 50% 3% 45%;
    height: 100%;
    align-items: center; 
    justify-items: center;
}

.historial {

}

.tiulo_carrera {
font-size: 2vw;
}

.carrera_num span {
    display: table-cell;
    vertical-align: middle;
    color:white;
    font-family: 'Times New Roman', Times, serif;
}

.numero_carrera {
    font-family: 'Times New Roman', Times, serif;
    font-size: 2vw;  
    margin-left: 0;
}

.tiulo_carrera span {
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif !important;
    transform: rotate(270deg);
    letter-spacing: 0.02vw;
    margin-right: 0vw;
    margin-top: -0.03vh;
    font-size: 2vw; 
}

.historial span {
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif !important;
    display: inline-block;
    font-size: 1.6vw;
    letter-spacing: 0.02vw;
}

.flecha {
    border-top: 4vh solid red;
    border-bottom: 4vh solid transparent;
    border-right: 6vh solid transparent;
    border-left: 6vh solid transparent;
}

.gri3 {
    grid-row: 2/3;
    grid-column: 3/4;
}

.corriendog {
    height: 100%;
    margin-top: -2vh;
}

.corrien {
    display: inline-block;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-style: italic;
    margin-left: 43vw;
    top: -8vh;
    font-size: 6vh;
    text-shadow: 0 0 1px red, 0 0 3px red, 0 0 5px red, 0 0 7px red, 0 0 9px red, 0 0 12px red, 0 0 16px red;
}

.dog {
    display: inline-block;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-style: italic;
    color: rgb(255, 255, 255);
    
    left: 58.5vw;
    font-size: 6.5vh;
    text-shadow: 0 0 1px red, 0 0 3px red, 0 0 5px red, 0 0 7px red, 0 0 9px red, 0 0 12px red, 0 0 16px red, 0 0 20px red;  
}

.gri4 {
    grid-row: 3/4;
    grid-column: 3/4;
}

.timepadre{
    display: table-cell;
    position: absolute;
    /* margin-left: 30.2vw; */
    height: 4vh;
    top: 8.8vh;
    border: 0.2em solid rgb(255, 251, 0);
    border-radius: 50px;
    background-color: rgb(0, 0, 255);
}

.time{
    display: cell;
    color: #fff;
    font-size: 2vh;
    font-weight: bolder;
    font-family: Verdana, Geneva, Tahoma, sans-serif;    
    width: 8vw;
    height: 0.06vh;
    text-align: center;
    vertical-align: middle;  
}

.progreso {    
    display: inline-block;
    width: 88%;
    height: 5vh;
    margin-left: 12%; 
    /* border-radius: 1.4em;   */
    vertical-align: top;
}

progress {
    vertical-align: middle;
    margin-top: -1vh;
    width: 99.6%;
    height: 3.5vh;
    border: 0.3em solid rgb(255, 255, 0);
    border-radius: 1.4em;  
    color: blue; 
    -webkit-appearance: none;
}
  
::-webkit-progress-bar {
    background: none;
    width: 100.15%;
    margin-left: -0.12%;
}

::-webkit-progress-value {   
    background-color: rgb(60, 0, 255);
    border-radius: 1.3em 0 0 1.3em;
}

.gri5 {
    grid-row: 4/5;
}

.bonusflot {
    display: table-cell;
}

.bonflot {
    font-family: 'Courier New', Courier, monospace;
    font-size: 1vh;
    color: rgb(81, 255, 0);
    line-height: 220%;
}

.equflot {  
    font-family: 'Courier New', Courier, monospace;
    font-size: 2vw; 
    color: rgb(255, 255, 255);  
    padding-bottom: 0vh; 
    line-height: 30%; 
    margin-top: 0.5vw;
    padding-top: 0.5vw;
}

.cont_12 {
    grid-template-columns: 0.9fr 0.5fr 0.5fr;
    grid-template-rows: repeat(8, minmax(8vh, 1fr));
    height: 100%; 
    row-gap: 0.25vw;
    column-gap: 0.3vw;  
}

.cont_12 a {
    font-size: 4vh;
    font-weight: bold;
}

.botonz_2 {
    justify-items: center;
    align-items: center;
    border-radius: 8px;
    font-weight:bold;
    background: linear-gradient(#fff, rgba(230,230,230,1), #fff, #fdfdfd, #fff, rgba(230,230,230,1));
}

.hisFirst {
    display: grid;
    grid-template-columns: 3vw 1fr 3vw;
    grid-template-rows: 100%;
    height: 100%;
    justify-items: center;
    align-items: center; 
}

.flotante {
    margin-top: 0vh;
    margin-left: 0.5vw;
    background-color: rgb(255, 0, 0);
    height: 3vh  
}

.tickHist {
    font-size: 1.5vw; 
    font-weight:bold;
}

.botonz_1 {
    justify-items: center;
    align-items: center;
    display: table;
    border-radius: 8px;   
}

.numz_1 {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    font-size: 3.7vh; 
    font-weight:bold;
    border-radius: 0.4em;
}

.botonz_1 .numz_11 {
    display: table-cell;
    margin: 0;
    vertical-align: middle;
    font: bold;
    font-size: 5.2vh; 
    border: 2px solid blue;
    border-radius: 0.2em; 
}

.historialPerro3 {
    text-align: center;
    background-color: white;
    color: black;
    border: solid;
}

.historialPerro1 {
    text-align: center;
    background-color: red;
    font-weight:bold;
    color:#fdfdfd;
}

.botonz_1:nth-child(4) {
    text-align: center;
    background: linear-gradient(#fff, rgba(230,230,230,1), #fff, #fdfdfd, #fff, rgba(230,230,230,1)); 

}

.botonz_1:nth-child(5) {
    text-align: center;
    font-weight: bolder;
    background: repeating-linear-gradient(#fff, #111, #fff, #111, #fff, #111, #fff, #111);
}

.historialPerro4 {
    text-align: center;
    background-color: rgb(29, 10, 234);
    color:#fdfdfd;
    border-color: rgb(11, 11, 11);
}

.botonz_1:nth-child(7) {
    background: linear-gradient(#fff, rgba(230,230,230,1), #fff, #fdfdfd, #fff, rgba(230,230,230,1));
    text-align: center;
}

.historialPerro2 {
    text-align: center;
    font-weight: bolder;
    background: repeating-linear-gradient(#fff, #111, #fff, #111, #fff, #111, #fff, #111);
    color: red
}

.historialPerro5 {
    text-align: center;
    background-color: rgb(255, 225, 0);
    color:#111;
}
.historialPerro6 {
    text-align: center;
    background-color: rgb(16, 94, 21);
    color: brown;
}
.historialPerro7 {
    text-align: center;
    background-color: black;
    color: white;
}
.historialPerro8 {
    text-align: center;
    background-color: violet;
    color:#111;
}

.botonz_1:nth-child(10) {
 background: linear-gradient(#fff, rgba(230,230,230,1), #fff, #fdfdfd, #fff, rgba(230,230,230,1));
    text-align: center;
}

.botonz_1:nth-child(11) {
    text-align: center;
    background-color: red;
    font-size: 1.3vw;
    font-weight:bold;
}

.botonz_1:nth-child(12) {
    background-color: white;
    font-size: 1.3vw;
    font-weight:bold;
    text-align: center;
}

.botonz_1:nth-child(13) {
    background: linear-gradient(#fff, rgba(230,230,230,1), #fff, #fdfdfd, #fff, rgba(230,230,230,1));
    text-align: center;
}

.botonz_1:nth-child(14) {
    background-color: red;
    font-size: 1.3vw;
    font-weight:bold;
    text-align: center;
}

.botonz_1:nth-child(15) {
    font-weight: bolder;
    text-align: center;
    background: repeating-linear-gradient(#fff, #111, #fff, #111, #fff, #111, #fff, #111);
}

.botonz_1:nth-child(16) {
    font-weight: bolder;
    background: linear-gradient(#fff, rgba(230,230,230,1), #fff, #fdfdfd, #fff, rgba(230,230,230,1));
    text-align: center;
}

.botonz_1:nth-child(17) {
    font-size: 1.3vw;
    font-weight:bold;
    text-align: center;
}


.botonz_1:nth-child(19) {
    font-weight: bolder;
    text-align: center;
    background: linear-gradient(#fff, rgba(230,230,230,1), #fff, #fdfdfd, #fff, rgba(230,230,230,1));
}

.botonz_1:nth-child(20) {
    font-weight:bold;
    text-align: center;
    border-color: rgb(11, 11, 11);
}

.botonz_1:nth-child(21) {
    background-color: rgb(255, 225, 0);
    font-weight:bold;
    text-align: center;
    color:#111;
}

.botonz_1:nth-child(22) {
    font-weight: bolder;
    text-align: center;
    background: linear-gradient(#fff, rgba(230,230,230,1), #fff, #fdfdfd, #fff, rgba(230,230,230,1));
}

.botonz_1:nth-child(23) {
    background-color: rgb(255, 225, 0);
    font-weight:bold;
    text-align: center;
    color:#111;
}

.botonz_1:nth-child(24) {
    font-weight: bolder;
    text-align: center;
    background: repeating-linear-gradient(#fff, #111, #fff, #111, #fff, #111, #fff, #111);
}

.gri6 {
    grid-row: 4/5;
    grid-column: 2/3;
}

.gri7 {
    grid-row: 4/6;
    grid-column: 3/4;
}

.contenedor {
    display: grid;
    grid-template-columns: repeat(7, minmax(1em, 1fr));
    grid-template-rows: repeat(7, minmax(0.5em, 1fr));
    background-color: rgb(215, 213, 237);
    border-radius: 1%;
    width: 100%;
    height: 100%;
    margin-bottom: 0;
    gap: .3em;  
}

.boton {
    display: table;
    border: 0.1px solid rgb(177, 175, 175);
    border-radius: 0.3em;
    width: 100%;
    height: 50%;
    filter: blur(0.2px);
}

.num {
border: #111;
}

.num_1 {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    font-size: 1vw; 
    left: 12vw;
    border: 2px solid blue;
    border-radius: 0.4em;
}

a img {
    display: flex;
   height: 100%;
   width: 100%;
} 

/* .cabc {
    display: table-cell;
    border: 2px solid rgb(223, 222, 222);    
} */

.boton:nth-child(9) {
    border: 1.8px solid red; 
    border-radius: .7em;
    /* height: 95%; */
}

.winPrimer1 {
    background: rgb(0, 255, 0);
    color: rgb(0, 0, 0);
    
}

.macho {
    background: rgb(0, 0, 255);
    color: rgb(255, 255, 0);
}

.hembra {
    background: rgb(255, 0, 138);
    color: rgb(255, 255, 0);
}

.paleHembra {
    color: rgb(255, 0, 138);
}

.paleMacho {
    color: rgb(0, 0, 255);
}

.boton:nth-child(17) {
    outline: 2px solid rgb(28, 8, 243);
    border-radius: .7em;
}

.boton:nth-child(25) {
    outline: 2.5px solid rgb(147, 147, 149);
    border-radius: .7em;   
}

.boton:nth-child(33) {
    outline: 2.3px solid rgb(1, 1, 1);
    border-radius: .7em;
}

.boton:nth-child(41) {
    outline: 2px solid rgb(255, 123, 0);
    border-radius: .7em;
}

.boton:nth-child(49) { 
    outline: 2.3px solid rgb(253, 233, 11);
    border-radius: .7em;
    
}

.gri8 {
    grid-row: 5/7;
    grid-column: 1/3;
}

.winJack {
    width: 85.5%;
    margin-top: 1vh;
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;
    grid-template-rows: 0.5fr 0.8fr 0.8fr;
    height: 98%;
}

.winJack div {
    border: 0.15vw solid rgb(0, 0, 255);
}

.winJack .cabeza {
    text-align: center;
}

.cabeza {
    padding-left: .01vw;
    font-size: 0.8vw;
    color: rgb(255, 255, 255);
}

.cuerpo {
    font-size: 3.5vh;
    color: rgb(255, 255, 0);
}

.gri9 {
    grid-row: 6/7;
    grid-column: 3/4;
}

.jackpots {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 24% 24% 29% 20%; 
}

.jackpots p {
    font-family: 'Times New Roman', Times, serif;
    display: table-cell;
    vertical-align: middle;
}

.jackpots:nth-child(4) {
    margin-left: 95%;
    padding-left: 100px;
}

.conteo_general {
    font-family: 'Times New Roman', Times, serif;
    top: 38vw;
    margin-left: 95%;
    font-size: 2vw;
    color: #fff; 
    display: inline-block;
    text-align: end;
}

.conteo_local {
    font-family: 'Times New Roman', Times, serif;
    font-size: 2vw; 
    color: #fff; 
    align-items: flex-start;
    align-content: flex-start;

}

.jackpot_general {
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif !important;
    font-size: 2vw;
    letter-spacing: 0.02px;
    color: rgb(255, 255, 0); 
}

.jackpot_local {
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif !important;
    font-size: 2vw;
    letter-spacing: 0.02px;
    color: rgb(255, 255, 0);   
}

.local {
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif !important;
    top: 0;
    margin-left: 80%;
    font-size: 2.5vw;
    letter-spacing: 0.02px;
    color: rgb(255, 255, 0);
}

.gridResult {
    display: grid;
    grid-template-rows: 10vh 12vh 10vh 17vh 15vh 15vh 1fr;
    grid-template-columns: 14% 18% 4% 18% 14% 17% 1fr;
    /* position: absolute; */
    height: 84vh;
    width: 75.1vw;
    margin-right: 2%;
    /* top: 1%; */
    /* background-image: url(segFond2.png); */
    background-size: cover;
    background-repeat: no-repeat;
}

.winGanador {
    grid-column: 3/6;
    
}

.resultados{
    position: absolute;
    height: 96.5vh;
    width: 98.5vw;
    left: 1vw;
    top:3vh;
    background-color: rgb(0, 0, 0);
    background-size: auto;
    aspect-ratio: none;
    border: 1px solid red;
    box-shadow:  0 0 0.8px red, 0 0 3px red, 0 0 5px red, 0 0 7px red, 0 0 9px red, 0 0 12px red, 0 0 14px red, 0 0 16px red;
    /* display: none; */
}

.winnerTitle {
    margin-top: 10vh;
    font-size: 5vw;
    font-weight: bold;
    color: rgb(255, 0, 0);
    text-shadow: 0 0 3px rgb(255, 255, 0), 0 0 5px rgb(255, 255, 0), 0 0 8px rgb(255, 255, 0), 0 0 10px rgb(255, 255, 0), 0 0 15px rgb(255, 0, 0), 0 0 19px rgb(255, 0, 0), 0 0 24px rgb(255, 0, 0), 0 0 28px rgb(255, 255, 0), 0 0 34px rgb(255, 255, 0), 0 0 38px rgb(255, 255, 0), 0 0 43px rgb(255, 255, 0), 0 0 45px rgb(255, 255, 0), 0 0 47px rgb(255, 255, 0);
}

.linePrimerLugar {
    font: bold;
    color: #fff;
    font-size: 5vw;
    line-height: 15vh;
}

.paleLineaSegundo {
    height: 90%;
}

.linePaleSegundo {
    font: bold;
    color: #fff;
    font-size: 5vw;
    height: 10vh;
    margin: 0;
    padding: 0;
    display: inline-block;
    line-height: 12.5vh;
}


        /*______Ganador del Primer lugar______*/

            /* Estilo de fondo para el Perro #1*/
.winprimero_1 {
    grid-column: 2/5;
    border: 1.5px solid rgba(255, 0, 0,);
    border-radius: 1.4vw;
    background: rgb(255, 0, 0);
    box-shadow: 0 0 2px rgb(255, 164, 164), 0 0 4px rgb(255, 159, 159), 0 0 7px rgb(255, 162, 162);
}

    /* Estilo de fondo para el Perro #2*/
.winprimero_2 {
    grid-column: 2/5;
    border: 1.5px solid rgb(0, 166, 221);
    border-radius: 1.4vw;
    background: rgb(0, 191, 255);
    box-shadow: 0 0 2px rgb(225, 249, 255), 0 0 4px rgb(197, 244, 254), 0 0 7px rgb(186, 241, 252);
}

    /* Estilo de fondo para el Perro #3*/
.winprimero_3 {
    grid-column: 2/5;
    border: 1.5px solid rgba(177, 177, 177, 0.966);
    border-radius: 1.4vw;
    background: linear-gradient(#fff, #e6eae6, #e6eae6, #e6eae6, #fff, rgba(230,230,230,1));
    box-shadow: 0 0 2px rgb(230, 229, 229), 0 0 4px rgb(215, 215, 215), 0 0 7px rgb(198, 198, 198);
}

    /* Estilo de fondo para el Perro #4*/
.winprimero_4 {
    grid-column: 2/5;
    border: 1.5px solid rgb(0, 0, 0);
    border-radius: 1.4vw;
    background: rgb(0, 0, 0);
    box-shadow: 0 0 2px rgb(73, 73, 73), 0 0 4px rgb(88, 88, 88), 0 0 7px rgb(109, 109, 109);
}

    /* Estilo de fondo para el Perro #5*/
.winprimero_5 {
    grid-column: 2/5;
    border: 1.5px solid rgba(255, 145, 0);
    border-radius: 1.4vw;
    background: rgb(255, 145, 0);
    box-shadow: 0 0 2px rgb(255, 199, 135), 0 0 4px rgb(255, 202, 141), 0 0 7px rgb(255, 202, 141);
}

    /* Estilo de fondo para el Perro #6*/
.winprimero_6 {
    grid-column: 2/5;
    border: 1.5px solid rgb(0, 0, 0);
    border-radius: 1.4vw;
    background: repeating-linear-gradient(#fff, #111, #fff, #111, #fff, #111, #fff, #111);
    box-shadow: 0 0 2px rgb(142, 142, 142), 0 0 4px rgb(124, 124, 124), 0 0 7px rgb(133, 133, 133);
}


    /* Grilla del primer lugar*/
.contWin {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 0.5fr 0.1fr 0.5fr;
    width: 100%;
    height: 100%;
}
    /*Ordinal primero del primer lugar*/
.iniWin1 {
    margin-left: 30%;
    font-size: 5vw;
    font-weight: bold;
    color: rgb(0, 0, 0);
    line-height: 14vh;
}

.iniWin2 {
    margin-left: 30%;
    font-size: 5vw;
    font-weight: bold;
    color: rgb(0, 0, 0);
    line-height: 14vh;
}

.iniWin3 {
    margin-left: 30%;
    font-size: 5vw;
    font-weight: bold;
    color: rgb(0, 0, 0);
    line-height: 14vh;
}

.iniWin4 {
    margin-left: 30%;
    font-size: 5vw;
    font-weight: bold;
    color: rgb(255, 255, 255);
    line-height: 14vh;
}

.iniWin5 {
    margin-left: 30%;
    font-size: 5vw;
    font-weight: bold;
    color: rgb(0, 0, 0);
    line-height: 14vh;
}

.iniWin6 {
    margin-left: 30%;
    font-size: 10vh;
    font-weight: bold;
    color: rgb(255, 255, 0);
    line-height: 14vh;
}

    /*parte superior de la flecha*/
.flechaUp1 {
    margin: 0;
    padding: 0;
    height: 50%;
    width: 50%;
    transform: skewX(55deg);
    background-color: rgb(0, 0, 0);
    outline: 0.1px solid rgb(0, 0, 0);
}

.flechaUp2 {
    margin: 0;
    padding: 0;
    height: 50%;
    width: 50%;
    transform: skewX(55deg);
    background-color: rgb(0, 0, 0);
    outline: 0.1px solid rgb(0, 0, 0);
}

.flechaUp3 {
    margin: 0;
    padding: 0;
    height: 50%;
    width: 50%;
    transform: skewX(55deg);
    background-color: rgb(0, 0, 0);
    outline: 0.1px solid rgb(0, 0, 0);
}

.flechaUp4 {
    margin: 0;
    padding: 0;
    height: 50%;
    width: 50%;
    transform: skewX(55deg);
    background-color: rgb(255, 255, 255);
    outline: 0.1px solid rgb(255, 255, 255);
}

.flechaUp5 {
    margin: 0;
    padding: 0;
    height: 50%;
    width: 50%;
    transform: skewX(55deg);
    background-color: rgb(0, 0, 0);
    outline: 0.1px solid rgb(0, 0, 0);
}

.flechaUp6 {
    margin: 0;
    padding: 0;
    height: 50%;
    width: 50%;
    transform: skewX(55deg);
    background-color: rgb(255, 255, 0);
    outline: 0.1px solid rgb(255, 255, 0);
}

    /*parte inferior de la flecha*/
.flechaDown1 {
    height: 49%;
    width: 50%;
    transform: skewX(125deg);
    background-color: rgb(0, 0, 0);
    outline: 0.1px solid rgb(0, 0, 0);   
}

.flechaDown2 {
    height: 49%;
    width: 50%;
    transform: skewX(125deg);
    background-color: rgb(0, 0, 0);
    outline: 0.1px solid rgb(0, 0, 0);   
}

.flechaDown3 {
    height: 49%;
    width: 50%;
    transform: skewX(125deg);
    background-color: rgb(0, 0, 0);
    outline: 0.1px solid rgb(0, 0, 0);   
}

.flechaDown4 {
    height: 49%;
    width: 50%;
    transform: skewX(125deg);
    background-color: rgb(255, 255, 255);
    outline: 0.1px solid rgb(255, 255, 255);   
}

.flechaDown5 {
    height: 49%;
    width: 50%;
    transform: skewX(125deg);
    background-color: rgb(0, 0, 0);
    outline: 0.1px solid rgb(0, 0, 0);   
}

.flechaDown6 {
    height: 49%;
    width: 50%;
    transform: skewX(125deg);
    background-color: rgb(255, 255, 0);
    outline: 0.1px solid rgb(255, 255, 0);   
}

.win1 {
    margin-left: 40%;
    font-size: 8vw;
    font-weight: bold;
    color: rgb(255, 255, 255);
    line-height: 14vh;
}

.win2 {
    margin-left: 40%;
    font-size: 8vw;
    font-weight: bold;
    color: rgb(255, 255, 255);
    line-height: 14vh;
}

    /*estilo para el perro #3 y #5 en primer lugar del pale*/
.win3 {
    margin-left: 40%;
    font-size: 8vw;
    font-weight: bold;
    color: rgb(0, 0, 0);
    line-height: 14vh;
}

    /*estilo para el perro #1; #2 y #4 en primer lugar del pale*/
.win4 {
    margin-left: 40%;
    font-size: 8vw;
    font-weight: bold;
    color: rgb(255, 255, 255);
    line-height: 14vh;
}

.win5 {
    margin-left: 40%;
    font-size: 8vw;
    font-weight: bold;
    color: rgb(0, 0, 0);
    line-height: 14vh;
}

    /*estilo para el perro #3 y #5 en primer lugar del pale*/
.win6 {
        margin-left: 40%;
        font-size: 18vh;
        font-weight: bold;
        color: rgb(255, 255, 0);
        line-height: 14vh;
}

        /*______Pale primer numero______*/

       /* Estilo de fondo para el Perro #3*/ 

.winPale1 {
    border: 0.9px solid rgb(71, 66, 66);
    height: 90%;
    border-radius: 1.4vw;
    background: rgb(255, 0, 0);
    box-shadow: 0 0 2px rgb(253, 162, 162), 0 0 4px rgb(255, 119, 119), 0 0 7px rgb(255, 69, 69);
}

    /* Estilo de fondo para el Perro #2*/
.winPale2 {
    border: 1.5px solid rgb(0, 152, 203);
    height: 90%;
    border-radius: 1.4vw;
    background: rgb(0, 191, 255);
    box-shadow: 0 0 2px rgb(227, 251, 255), 0 0 4px rgb(202, 239, 245), 0 0 7px rgb(166, 229, 241);
}

    /* Estilo de fondo para el Perro #3*/
.winPale3 {
    border-radius: 1.4vw;
    background: linear-gradient(#fff, #e6eae6, #e6eae6, #e6eae6, #fff, rgba(230,230,230,1));
    height: 90%;
    box-shadow: 0px 2px 6px rgb(173, 173, 173), 0 0 2px rgb(145, 145, 145), 0 0 4px rgb(210, 210, 210);
    border: 1.5px solid rgba(153, 153, 153, 0.966);
}

    /* Estilo de fondo para el Perro #4*/
.winPale4 {
    border: 1.5px solid rgb(0, 0, 0);
    height: 90%;
    border-radius: 1.4vw;
    background: rgb(0, 0, 0);
    box-shadow: 0 0 2px rgb(182, 182, 182), 0 0 4px rgb(135, 135, 135), 0 0 7px rgb(98, 98, 98);
}

    /* Estilo de fondo para el Perro #5*/
.winPale5 {
    border: 1.5px solid rgba(255, 145, 0);
    height: 90%;
    border-radius: 1.4vw;
    background: rgb(255, 145, 0);
    box-shadow: 0 0 2px rgb(255, 222, 179), 0 0 4px rgb(255, 211, 154), 0 0 7px rgb(255, 179, 81);
}

    /* Estilo de fondo para el Perro #6*/
.winPale6 {
    border: 1.5px solid rgb(143, 143, 143);
    height: 90%;
    border-radius: 1.4vw;
    background: repeating-linear-gradient(#fff, #111, #fff, #111, #fff, #111, #fff, #111);
    box-shadow: 0 0 2px rgb(144, 144, 144), 0 0 4px rgb(114, 114, 114), 0 0 7px rgb(67, 67, 67);
}

    /* Grilla del primer y segundo numero del Pale*/
.contWinPale {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 0.4fr 0.1fr 0.6fr;
    width: 100%;
    height: 100%;
}
    /*Ordinal primero para todos los pale*/
.iniGanador1 {
    margin-left: 0%;
    font-size: 2.7vw;
    font-weight: bold;
    color: rgb(0, 0, 0);
    line-height: 11vh;
}

.iniGanador2 {
    margin-left: 0%;
    font-size: 2.7vw;
    font-weight: bold;
    color: rgb(0, 0, 0);
    line-height: 11vh;
}

.iniGanador3 {
    margin-left: 0%;
    font-size: 2.7vw;
    font-weight: bold;
    color: rgb(0, 0, 0);
    line-height: 11vh;
}

.iniGanador4 {
    margin-left: 0%;
    font-size: 2.7vw;
    font-weight: bold;
    color: rgb(255, 255, 255);
    line-height: 11vh;
}

.iniGanador5 {
    margin-left: 0%;
    font-size: 2.7vw;
    font-weight: bold;
    color: rgb(0, 0, 0);
    line-height: 11vh;
}

.iniGanador6 {
    margin-left: 0%;
    font-size: 2.7vw;
    font-weight: bold;
    color: rgb(255, 255, 0);
    line-height: 11vh;
}

/*parte superior de la flecha*/
.arrowUp1 {
    height: 50.5%;
    width: 75%;
    transform: skewX(55deg);
    background-color: rgb(0, 0, 0);
    outline: 0.001px solid rgb(0, 0, 0);
}

.arrowUp2 {
    height: 50.5%;
    width: 75%;
    transform: skewX(55deg);
    background-color: rgb(0, 0, 0);
    outline: 0.001px solid rgb(0, 0, 0);
}

.arrowUp3 {
    height: 50.5%;
    width: 75%;
    transform: skewX(55deg);
    background-color: rgb(0, 0, 0);
    outline: 0.001px solid rgb(0, 0, 0);
}

.arrowUp4 {
    height: 50.5%;
    width: 75%;
    transform: skewX(55deg);
    background-color: rgb(255, 255, 255);
    outline: 0.001px solid rgb(255, 255, 255);
}

.arrowUp5 {
    height: 50.5%;
    width: 75%;
    transform: skewX(55deg);
    background-color: rgb(0, 0, 0);
    outline: 0.001px solid rgb(0, 0, 0);
}

.arrowUp6 {
    height: 50.5%;
    width: 75%;
    transform: skewX(55deg);
    background-color: rgb(255, 255, 0);
    outline: 0.001px solid rgb(255, 255, 0);
}

/*parte inferior de la flecha*/
.arrowDown1 {
    height: 50%;
    width: 75%;
    transform: skewX(125deg);
    background-color: rgb(0, 0, 0);
    outline: 0.001px solid rgb(0, 0, 0);
}

.arrowDown2 {
    height: 50%;
    width: 75%;
    transform: skewX(125deg);
    background-color: rgb(0, 0, 0);
    outline: 0.001px solid rgb(0, 0, 0);
}

.arrowDown3 {
    height: 50%;
    width: 75%;
    transform: skewX(125deg);
    background-color: rgb(0, 0, 0);
    outline: 0.001px solid rgb(0, 0, 0);
}

.arrowDown4 {
    height: 50%;
    width: 75%;
    transform: skewX(125deg);
    background-color: rgb(255, 255, 255);
    outline: 0.001px solid rgb(255, 255, 255);
}

.arrowDown5 {
    height: 50%;
    width: 75%;
    transform: skewX(125deg);
    background-color: rgb(0, 0, 0);
    outline: 0.001px solid rgb(0, 0, 0);
}

.arrowDown6 {
    height: 50%;
    width: 75%;
    transform: skewX(125deg);
    background-color: rgb(255, 255, 0);
    outline: 0.001px solid rgb(255, 255, 0);
}

    /*estilo para el perro # 1,2 y 4 en primer lugar del pale*/
.ganadorPale1 {
    margin-left: 45%;
    font-size: 5vw;
    font-weight: bold;
    color: rgb(255, 255, 255);
    line-height: 11.9vh;
}

.ganadorPale2 {
    margin-left: 45%;
    font-size: 5vw;
    font-weight: bold;
    color: rgb(255, 255, 255);
    line-height: 11.9vh;
}

   /*estilo para el perro # 3 y 5 en primer lugar del pale*/
.ganadorPale3 {
    margin-left: 45%;
    font-size: 5vw;
    font-weight: bold;
    color: rgb(0, 0, 0);
    line-height: 11.9vh;
}

.ganadorPale4 {
    margin-left: 45%;
    font-size: 5vw;
    font-weight: bold;
    color: rgb(255, 255, 255);
    line-height: 11.9vh;
}

.ganadorPale5 {
    margin-left: 45%;
    font-size: 5vw;
    font-weight: bold;
    color: rgb(0, 0, 0);
    line-height: 11.9vh;
}

   /*estilo para el perro #6 en primer lugar del pale*/
.ganadorPale6 {
    margin-left: 45%;
    font-size: 5vw;
    font-weight: bold;
    color: rgb(255, 255, 0);
    line-height: 11.9vh;
}



/*añadir este fragmento CSS en Line_responsive.css*/

.style1 {
    background: linear-gradient(rgb(255, 240, 240), rgb(255, 155, 155), rgb(255, 72, 72), rgb(255, 0, 0), rgb(248, 0, 0), rgb(240, 0, 0), rgb(236, 0, 0), rgb(232, 0, 0), rgb(242, 0, 0), rgb(255, 36, 36), rgb(249, 61, 61), rgb(217, 125, 125));
    border-radius: 0.7vw;
    color: white;
}

.ordinalSecond {
    grid-column: 1/4;
    display: flex;
    align-items: center;
    font-weight: bold;
    justify-content: center;
    font-size: 1vw;   
}

.dosHeader {

    font-size: 2vw;
    font-weight: bold;
    font-family: Bahnschrift;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 5px rgb(0, 0, 0), 0 0 3px rgb(255, 255, 255), 0 0 4px rgb(255, 255, 255);
    display: flex; 
    align-items: center;
}

.dirLeft {
    grid-column: 2/4;
    height: 3vw;
    width: 3.1vw;
    border-left: 0.62vw solid rgb(0, 0, 0);
    border-bottom: 0.61vw solid rgb(0, 0, 0);
    rotate: 315deg;
    margin-left: 0.5vw;
    margin-top: -3vh;
   
}

.style2 { 
    background: linear-gradient(rgb(255, 240, 240), rgb(0, 104, 224), rgb(0, 104, 224), rgb(0, 112, 241), rgb(0, 98, 210), rgb(0, 98, 210), rgb(0, 112, 241), rgb(0, 119, 255), rgb(0, 119, 255), rgb(0, 119, 255), rgb(64, 126, 196), rgb(10, 91, 205));
    border-radius: 0.7vw;
    color: brown;
    
}

.style3 { 
    background: linear-gradient(#fff, rgba(230,230,230,1), #fff, #fdfdfd, #fff, rgba(230,230,230,1));
    outline: 0.12vw solid rgb(193, 193, 193);
    border-radius: 0.7vw;
    color: black;
}

.subStyle3 .tresheader {
    color: rgb(0, 0, 0);
    text-shadow: 0 0 2px rgb(101, 101, 101);
    font-size: 2vw;
}

.style4 {
    background: rgb(0, 0, 0);
    outline: 0.12vw solid rgb(70, 70, 70);
    background: linear-gradient(#a8e3f1, #76f6f4, #56dff1, #25d4f7, #29acf8, #0f9ef6, #76c5f6,rgb(179, 179, 241));
    border-radius: 0.7vw;
    color: white;
}

.dirLeft4 .dirLeft {
    border-left: 0.6vw solid rgb(255, 255, 255);
    border-bottom: 0.6vw solid rgb(255, 255, 255);
}

.style1 .ordinalCuatro {
    color: rgb(255, 255, 255);
}

.style5 {
    background: linear-gradient(rgb(255, 128, 0), rgb(251, 180, 109), rgb(252, 167, 81), rgb(255, 143, 32), rgb(255, 128, 0), rgb(255, 128, 0), rgb(255, 128, 0), rgb(253, 127, 0), rgb(255, 136, 17), rgb(255, 150, 45), rgb(255, 193, 130), rgb(252, 184, 116));
    border-radius: 0.7vw;
    color: black;
}

.fiveHeader {
    color: black;
    text-shadow: 0 0 4px rgb(255, 255, 255);
}

.style2{
    background: repeating-linear-gradient(#fff, #111, #fff, #111, #fff, #111, #fff, #111);
    outline: 1px solid rgb(0, 0, 0);
    color: brown;
}

.style6 {
    background: linear-gradient(#688a6c,#398242,#177422,#087c16,#00800f,#0d851b,#22862e,#408b48,#658e69);
    border-radius: 0.7vw;
    color: white;
}
.style7 {
    background: linear-gradient(#404040,#383838,#161616,#272727,#101010,#000000,#181818,#151515,#2b2a2a);
    border-radius: 0.7vw;
    color: yellow;
    align-items: center;

}
.style8 {
    background: linear-gradient(#ee82ee,#f578f5,#f161f1,#ec24ec,#ed07ed,#f020f0,#f144f1,#f357f3,#ee82ee,#ee82ee);
    border-radius: 0.7vw;
    color: black

}

.style1 .ordinal6 {
    color: rgb(255, 255, 0);
    text-shadow: 0 0 3px rgb(0, 0, 0), 0 0 4px rgb(0, 0, 0), 0 0 5px rgb(0, 0, 0);
}

.seisHeader {
    color: rgb(255, 255, 0);
    text-shadow: 0 0 4px rgb(0, 0, 0);
}

.dirLeft6 .dirLeft {
    border-left: 0.62vw solid rgb(255, 255, 0);
    border-bottom: 0.61vw solid rgb(255, 255, 0);
    
}

.verticalStyle1 {
    grid-template-columns: 0.1fr 0.2fr 0.15fr 0.05fr 0.4fr 0.55fr;
    grid-template-rows: 0.5fr 1fr;
    background: linear-gradient(rgb(255, 240, 240), rgb(255, 155, 155), rgb(255, 72, 72), rgb(255, 0, 0), rgb(248, 0, 0), rgb(240, 0, 0), rgb(236, 0, 0), rgb(232, 0, 0), rgb(242, 0, 0), rgb(255, 36, 36), rgb(249, 61, 61), rgb(217, 125, 125));
    width: 100%;
    height: 5vh;
    border-radius: 0.7vw;
    margin: 0;
    padding: 0;
    outline: 0.12vw solid rgb(248, 128, 128);
}

.ordinalFirst {
    grid-column: 5/6;
    grid-row: 1/3;
    font-weight: bold;
    color: black;
    font-size: 1vw; 
    margin-left: 2.4vw; 
    margin-top: 0.8vh; 
}

.unoAside {
    grid-column: 2/4;
    grid-row: 1/3;
    font-size: 4.5vw;
    font-weight: bold;
    font-family: Bahnschrift;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 5px rgb(0, 0, 0), 0 0 3px rgb(255, 255, 255), 0 0 2px rgb(255, 255, 255);
    display: flex; 
    align-items: center;
    margin-left: 1vw;
}

.dirEst {
    grid-column: 6/7;
    grid-row: 1/3;
    height: 3vw;
    width: 3vw;
    border-left: 0.62vw solid rgb(0, 0, 0);
    border-bottom: 0.61vw solid rgb(0, 0, 0);
    border-top: 0.03vw solid rgb(0, 0, 0);
    border-right: 0.01vw solid rgb(0, 0, 0);
    rotate: 230deg;
    margin-top: 1.vh;
    margin-left: -2.0vw;
    padding: 0;
   
}

.contDirEst .dirEst4 {
    border-left: 0.6vw solid rgb(255, 255, 255);
    border-bottom: 0.6vw solid rgb(255, 255, 255);
    border-top: 0.03vw solid rgb(255, 255, 255);
    border-right: 0.01vw solid rgb(255, 255, 255);
}

.ordinalfirst4 {
    color: rgb(255, 255, 255);
}

.ordinalfirst5 {
    color: rgb(0, 0, 0);
}

.unoAside6 {
    color: rgb(255, 255, 0);

}

.ordinalFirst6 {
    color: rgb(255, 255, 0);
    text-shadow: 0 0 1px rgb(0, 0, 0), 0 0 3px rgb(0, 0, 0), 0 0 5px rgb(0, 0, 0);
}

.contDirEst .dirEst6 {
    border-left: 0.6vw solid rgb(0, 0, 0);
    border-bottom: 0.6vw solid rgb(0, 0, 0);
    
}

.boton .neutral{
    font-size: 1.8vw;
    margin-left: 0.1vw;
}

.boton .newDir {
    rotate: 270deg;
    display: flex;
    align-items: flex-end;
    align-content: flex-end;
    justify-content: end;
    justify-items: end;
    margin-top: 43%;
    margin-left: 17%;

}

.num1{
    background-color: red;
}
.num2{
    background-color: #111;
}
.num3{
    background-color: white;
    color: black;
}
.num4{
    background-color: blue;
}

.num5{
    background-color: yellow;
}
.num6{
    background-color: green;
}
.num7{
    background-color: black;
}
.num8{
    background-color: violet;
}
