.logo{
    position: absolute;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    text-align: center;
    font-size: 5rem;
    margin-left: 73%;
    line-height: 250px;
    top: 1vw;
}
.img-logo{
    position: absolute;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    text-align: center;
    font-size: 5rem;
    margin-left: 73%;
    line-height: 250px;
    top: 1vw;   
}
.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Relación de aspecto del reproductor: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}